import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getUrl } from "../../utils/urlMap";
import * as styles from "./optimizeScrap.module.scss";
import DomainContext from "../../context/DomainContext";
import { withNamespaces } from "react-i18next";
import scrapImage1 from "../../images/scrap_mountain.png";
import scrapImage2 from "../../images/scrap_mountain_2.png";

const OptimizeScrap = props => {
  const { useDarkMode, useMetalImage } = props;
  const displayImage = useMetalImage ? scrapImage2 : scrapImage1;
const {t} = props;
const domain = useContext(DomainContext);
  const data = useStaticQuery(graphql`
    query {
      scrapImage1: file(relativePath: { eq: "scrap_mountain.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      scrapImage2: file(relativePath: { eq: "scrap_mountain_2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div className={useDarkMode ? styles.darkModeContainer : styles.container}>
      <div className={styles.bigAmount}>
        <div className={styles.hideMobile}>
          <GatsbyImage
            className={styles.image}
            alt="scrap"
            image={
              (useMetalImage ? data.scrapImage1 : data.scrapImage2)
                ?.childImageSharp?.gatsbyImageData
            }
          />
        </div>
        <div className={styles.textWrapper}>
          <div>
            <span
              className={`${styles.greyHeading} ${useDarkMode &&
                styles.darkModeTitle}`}
            >
              {" "}
              Schrott24 PRO 
            </span>
            <p
              className={`${styles.title} ${useDarkMode &&
                styles.darkModeTitle}`}
            >
              {t("Schrotterlös für Ihr Industrie-Unternehmen optimieren")}
            </p>
            <div className={styles.mobileImageWrapper}>
              <GatsbyImage
                className={styles.mobileImage}
                alt="scrap mobile"
                image={
                  (useMetalImage ? data.scrapImage1 : data.scrapImage2)
                    ?.childImageSharp?.gatsbyImageData
                }
              />
            </div>
            <p
              className={`${styles.subtitle} ${useDarkMode &&
                styles.darkModeTitle}`}
            >
              {t("Profitieren Sie von unserem Partner-Netzwerk, das es ermöglicht den Erlös und Recyclingprozess für Ihr Altmetall zu optimieren. Als größte europäische Altmetall-Handelsplattform und voll lizenzierter Altmetallhändler, bieten wir Ihrem Unternehmen ein zuverlässliches All-in-One Service.")}
            </p>
            <div className={styles.orangeButton}>
              <a className={styles.button} href={getUrl(domain,"pro")} target="_blank">
                {t("JETZT ANFRAGEN")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(OptimizeScrap);