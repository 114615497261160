import React, { useState, useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withNamespaces } from "react-i18next";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import styles from "./marktdatenTable.module.scss";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import { navigate } from "gatsby";
import { connect } from "react-redux";

import { monthName, formatNumberMarktdatenTables, formatDate } from "./utils";
import { Link } from "gatsby";
import DomainContext from "../../context/DomainContext";
import { changeInitalDisplay } from "../../action/MarktdatenActions";
const StyledTableCell = withStyles(theme => ({
  head: {
    fontFamily: "Roboto",
    fontStyle: "norma",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    height: "31px",
    padding: "10px 16px",
    letterSpacing: "0.15px",
    "&:first-child": {
      color: "rgba(0, 0, 0, 0.38)",
    },

    "@media (max-width: 1140px)": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      padding: "10px 15px ",
      height: "27px",
    },
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:hover": {
      cursor: "pointer",

      "& th": {
        color: "#F0A60E;",
        background: "rgba(240, 166, 14, 0.04)",
      },
      "& td a svg": {
        color: "#F0A60E;",
      },
      "& td": {
        color: "#F0A60E;",
        background: "rgba(240, 166, 14, 0.04)",
      },
    },
    "& th": {
      padding: "10px 16px ",
      height: "31px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "left",
      letterSpacing: "0.5px",
    },

    "& td": {
      padding: "10px 16px ",
      height: "31px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "right",
      letterSpacing: "0.5px",
    },

    "& td:nth-child(2)": {
      width: "200px",
    },

    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFA",
    },

    "& th:nth-child(1)": {
      width: "352px",
    },
    "& td:nth-child(2)": {
      width: "228px",
    },
    "& td:nth-child(3)": {
      width: "228px",
    },

    "& td:last-child": {
      color: "#DADADA",
      width: "88px",
      textAlign: "center",
    },

    "@media (max-width: 1140px)": {
      "& th": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.25px",
        padding: "10px 15px ",
        height: "27px",
      },

      "& td": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.25px",
        padding: "10px 15px ",
        height: "27px",
      },

      "& th:nth-child(1)": {
        width: "214px",
      },
      "& td:nth-child(2)": {
        width: "148px",
      },
      "& td:nth-child(3)": {
        width: "148px",
      },
      "& td:last-child": {
        color: "#DADADA",
        width: "90px",
        textAlign: "center",
      },
    },

    "@media (max-width: 768px)": {
      "& th": {
        letterSpacing: "0.1px",
      },

      "& td": {
        letterSpacing: "0.1px",
      },
      "& td:last-child": {
        color: "#DADADA",
        width: "63px",
        textAlign: "center",
      },
      "td:last-child": {},
    },
  },
}))(TableRow);

const MarktdatenTable = ({ changeInitalDisplay, ...props }) => {
  const [disableHover, setDisableHover] = useState(false);
  const { t } = props;

  const domain = useContext(DomainContext);
  var convertUrl = {
    LME_Cu_cash: "kupfer",
    LME_Pb_cash: "blei",
    LME_Al_cash: "aluminium",
    LME_Sn_cash: "zinn",
    LME_Zn_cash: "zink",
  };

  return (
    <div className={styles.tableContainer}>
      <h4 className={styles.tableTitle}>
        {typeof props.tableTitle === "string"
          ? t(props.tableTitle)
          : props.tableTitle}{" "}
        {props.todayDate}
      </h4>

      <div className={styles.tableWrapper}>
        <Table
          aria-label="customized table"
          className={`${styles[props.tableInfo.id]}  ${styles.table}`}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell className={styles.tableHeadCell}>
                {props.tableInfo.head[0] !== undefined
                  ? t(props.tableInfo.head[0])
                  : ""}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeadCell} align="right">
                {" "}
                {props.tableInfo.head[1] !== undefined
                  ? props.tableInfo.head[1].includes("-")
                    ? formatDate(props.tableInfo.head[1], domain, true)
                    : t(props.tableInfo.head[1])
                  : ""}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeadCell} align="right">
                {" "}
                {props.tableInfo.head[2] !== undefined
                  ? props.tableInfo.head[2].includes("-")
                    ? formatDate(props.tableInfo.head[2], domain, true)
                    : t(props.tableInfo.head[2])
                  : ""}
              </StyledTableCell>
              <StyledTableCell className={styles.tableHeadCell} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableInfo.body.map(row => (
              <StyledTableRow
                onClick={() =>
                  navigate(
                    convertUrl[row[row.length - 1]] !== undefined
                      ? convertUrl[row[row.length - 1]]
                      : row[row.length - 1]
                  )
                }
                className={`${styles.tableRow} ${
                  disableHover ? styles.disableHover : ""
                }`}
                key={row[0]}
              >
                {" "}
                <StyledTableCell component="th" scope="row">
                  {t(row[0])}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatNumberMarktdatenTables(domain, row[1], 0)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatNumberMarktdatenTables(domain, row[2], 0)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className={styles.linkContainer}>
                    <Link
                      className={styles.link}
                      onMouseEnter={() => setDisableHover(true)}
                      onMouseLeave={() => setDisableHover(false)}
                      to={
                        convertUrl[row[row.length - 1]] !== undefined
                          ? convertUrl[row[row.length - 1]]
                          : row[row.length - 1]
                      }
                      onClick={() => {
                        changeInitalDisplay("table");
                      }}
                    >
                      <TableChartOutlinedIcon />
                    </Link>

                    <Link
                      className={styles.link}
                      onMouseEnter={() => setDisableHover(true)}
                      onMouseLeave={() => setDisableHover(false)}
                      to={
                        convertUrl[row[row.length - 1]] !== undefined
                          ? convertUrl[row[row.length - 1]]
                          : row[row.length - 1]
                      }
                      onClick={() => {
                        changeInitalDisplay("graph");
                      }}
                    >
                      <InsertChartOutlinedIcon />
                    </Link>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { changeInitalDisplay };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(MarktdatenTable));
