import React, { useEffect, useState, useContext } from "react";

import Helmet from "react-helmet";
import { I18nextProvider } from "react-i18next";
import {
  formatDateForTable,
  formatDate,
} from "../../components/marktdaten-tables/utils";
import MarktdatenTable from "../../components/marktdaten-tables/MarktdatenTable";
import SEOComponent from "../../components/Shared/Seo";
import NavMenu from "../../components/nav-menu/NavMenu";
import MarketInsights from "../../components/main-page/sections/marketInsights/MarketInsights";
import OptimizeScrap from "../../components/optimize-scrap/OptimizeScrap";
import i18n from "../../i18n/i18n";
import Footer from "../../components/main-page/sections/footer/Footer";
import MarktdatenTitle from "../../components/marktdaten-tables/MarktdatenTitle";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import DomainContext from "../../context/DomainContext";
import LinkToDisclaimer from "../../components/marktdaten-tables/LinkToDisclaimer";
import styles from "./index.module.scss";
import moment from "moment";
import LinkToSource from "../../components/marktdaten-tables/LinkToSource";

const MarktdatenPage = () => {
  const domain = useContext(DomainContext);

  const [lmePrices, setLmePrices] = useState({
    id: "lmePrice",
    head: ["in EUR/Tonne", "Settlement Kasse", "3 Monate"],
    body: [],
    rowOrder: ["Copper", "Tin", "Lead", "Zinc", "Aluminium", "Nickel"],
  });
  const [lmeStocks, setLmeStocks] = useState({
    id: "lmeStock",
    head: ["in Tonnen"],
    body: [],
    rowOrder: ["Copper", "Tin", "Lead", "Zinc", "Aluminium", "Nickel"],
  });
  const [exchangeRates, setExchangeRates] = useState({
    id: "forex",
    head: [" "],
    body: [],
    rowOrder: [
      "EUR/USD LME-FX-rate (MTLE)",
      "ECB-Fixing (14:15 Uhr)",
      "EUR/USD-Basis DEL-Notiz",
    ],
  });
  const [germanMetalPrices, setGermanMetalPrices] = useState({
    id: "germanQuotation",
    head: ["in Euro per 100 kg"],
    body: [],
    rowOrder: [
      "lower DEL-Notiz",
      "higher DEL-Notiz",
      "MK Copper",
      "Lead in cables",
      "Bronze 94/6",
      "Aluminium conductive (until May 23, 2014)",
      "Aluminium for cables",
      "Brass MS 58, 1st processing level",
      "Brass MS 58, 2st processing level",
      "MP 58",
      "Brass MS 63 for wire",
      "Brass MS 63/37",
    ],
  });

  useEffect(() => {
    var query = ` 

    query test {
    home {
        lmePrices {
          leftColumnTitle
          rightColumnTitle
          data {
            name
            id
            data
          }
        }
        lmeStocks {
          leftColumnTitle
          rightColumnTitle
          data {
            name
            id
            data
          }
        }
        exchangeRates {
          leftColumnTitle
          rightColumnTitle
          data {
            name
            id
            data
          }
        }
        germanMetalPrices {
          leftColumnTitle
          rightColumnTitle
          data {
            name
            id
            data
          }
        }
        preciousMetals {
          leftColumnTitle
          rightColumnTitle
          data {
            name
            id
            data
          }
        }
      }
    }`;

    fetch(
      "https://2xerhhcoxf.execute-api.eu-central-1.amazonaws.com/dev/graphql",
      {
        method: "POST",
        headers: {},
        body: JSON.stringify({
          query,
        }),
      }
    )
      .then(r => r.json())
      .then(rst => {
        const buildTableInfo = (info, tableObj) => {
          var rst = { ...tableObj };

          rst.head = [...rst.head, info.leftColumnTitle, info.rightColumnTitle];
          rst.date = moment(info.leftColumnTitle, "DD-MM-YYYY").toDate();
          rst.body = rst.rowOrder.map(rowTitle => {
            var match = info.data.find(function(rowInfo, index) {
              if (rowInfo.name == rowTitle) return true;
            });

            if (match === undefined) {
              return [rowTitle, "-", "-"];
            } else {
              return [rowTitle, ...match.data, match.id];
            }
          });

          if (rst.id === "germanQuotation") {
            // We don't have this data for today. Only contains historic data so
            // we need to add these results manually in order to see the historic values
            rst.body[0].push("DEL_low");
            rst.body[1].push("DEL_high");
            rst.body[3].push("Pb_Cable");
            rst.body[5].push("Al_unalloyed");
            rst.body[6].push("Al_cables");
          }

          return rst;
        };

        setLmePrices(buildTableInfo(rst.data.home.lmePrices, lmePrices));

        setLmeStocks(buildTableInfo(rst.data.home.lmeStocks, lmeStocks));
        setExchangeRates(
          buildTableInfo(rst.data.home.exchangeRates, exchangeRates)
        );
        setGermanMetalPrices(
          buildTableInfo(rst.data.home.germanMetalPrices, germanMetalPrices)
        );
      })
      .catch(Any => {
        console.log(Any);
      });
  }, []);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="note" content="environment=development" />
      </Helmet>
      <SEOComponent page="marktdaten" />
      <NavMenu />

      <Breadcrumb arrPath={[{ path: null, label: "Marktdaten" }]} />

      <MarktdatenTitle />
      <div className={styles.groupOfTables}>
        <MarktdatenTable
          tableTitle={
            domain !== "en" ? (
              <>
                LME-Notierung Übersicht in <nobr>Euros</nobr>
              </>
            ) : (
              <>Overview of LME quotes in Euros</>
            )
          }
          tableInfo={lmePrices}
          todayDate={formatDate(
            formatDateForTable(lmePrices.date),
            domain,
            true
          )}
        />

        <MarktdatenTable
          tableTitle={"Bestände in LME Lagerhäusern"}
          tableInfo={lmeStocks}
          todayDate={formatDate(
            formatDateForTable(lmeStocks.date),
            domain,
            true
          )}
        />

        <MarktdatenTable
          tableTitle={"Devisen Fixing"}
          tableInfo={exchangeRates}
        />

        <MarktdatenTable
          tableTitle={"Deutsche Metallnotierung"}
          tableInfo={germanMetalPrices}
        />
        <LinkToDisclaimer />
        <LinkToSource to="https://www.lme.com/en/Metals/Non-ferrous" />
      </div>

      <OptimizeScrap />
      <Footer />
    </I18nextProvider>
  );
};

export default MarktdatenPage;
