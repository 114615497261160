import React , {  useContext } from "react"; 
import { withNamespaces } from "react-i18next";
import DomainContext from "../../context/DomainContext";

import styles from "./marktdatenTable.module.scss";



const MarktdatenTitle = (props) => {
  const { t } = props;
  const domain = useContext(DomainContext);
  return( 
    <h1 className={styles.title}>{t("Metall LME - Preisentwicklung")}</h1>
)}
;

export default  withNamespaces()(MarktdatenTitle);
