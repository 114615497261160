import React , {  useContext,useState,useEffect } from "react"; 
import { withNamespaces } from "react-i18next";
import DomainContext from "../../context/DomainContext";
import { Link } from "gatsby";
import styles from "./marktdatenTable.module.scss";



const LinkToDisclaimer = (props) => {
  useEffect(() => {
    setHost(typeof window !== 'undefined' ?window.location.protocol + "//" + window.location.hostname  : '');  

  }, []);
  const { t } = props;
  const [host, setHost] = useState("");
  const domain = useContext(DomainContext);
  return( 
    <Link className={styles.disclaimerLink} to={host+"/marktdaten/disclaimer"}>
    {t("*Rechtliche Hinweise")}
  </Link>
)}
; 

export default  withNamespaces()(LinkToDisclaimer);